import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Applications]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	APPLICATIONS_LOAD: `${prefix} applications_load`,
	APPLICATIONS_SET: `${prefix} applications_set`,
	APPLICATION_LOAD: `${prefix} application_load`,
	APPLICATION_SET: `${prefix} application_set`,
	APPLICATION_UPDATE: `${prefix} application_update`,
	APPLICATION_CREATE: `${prefix} application_create`,
	APPLICATION_ERROR_SET: `${prefix} application_error_set`,
	APPLICATION_LOADING_SET: `${prefix} application_loading_set`,
	ATTACHMENT_DELETE: `${prefix} attachment_delete`,
	APPLICATION_DELETE: `${prefix} application_delete`,
	APPLICATION_DUPLICATE: `${prefix} application_duplicate`
};

const actions = {
	...TYPES,
	setApplications: makeActionCreator(TYPES.APPLICATIONS_SET, 'applications'),
	loadApplications: makeActionCreator(TYPES.APPLICATIONS_LOAD, 'externalUserId'),
	setApplication: makeActionCreator(TYPES.APPLICATION_SET, 'application'),
	loadApplication: makeActionCreator(TYPES.APPLICATION_LOAD, 'id'),
	updateApplication: makeActionCreator(TYPES.APPLICATION_UPDATE, 'id', 'formData'),
	createApplication: makeActionCreator(TYPES.APPLICATION_CREATE, 'formData', 'name', 'step'),
	setApplicationError: makeActionCreator(TYPES.APPLICATION_ERROR_SET, 'applicationError'),
	setApplicationLoading: makeActionCreator(TYPES.APPLICATION_LOADING_SET, 'isApplicationLoading'),
	deleteAttachment: makeActionCreator(TYPES.ATTACHMENT_DELETE, 'id', 'fileName', 'application'),
	deleteApplication: makeActionCreator(TYPES.APPLICATION_DELETE, 'applications', 'id'),
	duplicateApplication: makeActionCreator(TYPES.APPLICATION_DUPLICATE, 'id', 'branchAlias')
};

export default actions;
export { actions as applicationsAction };
