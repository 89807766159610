import { API_ROUTES } from '../constants/routes';
import { NotificationUtils } from '../utils/NotificationUtils';
import { BaseService } from './BaseService';

class OnlineFormService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  getApplications = async (payload) => {
    const { externalUserId } = payload;
    const { agentBackOffice } = this;

    try {
      const url = `v1.0/api/applications?external_user_id=${externalUserId}`;

      return await agentBackOffice.get(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.errorText || err.message;
    }
  };

  createApplication = async (payload) => {
    const { formData } = payload;
    const { agentBackOffice } = this;

    try {
      const url = 'v1.0/api/application';

      return await agentBackOffice.post(url, formData);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.response;
    }
  };

  getApplicationsById = async (id) => {
    const { agentBackOffice } = this;

    try {
      const url = `v1.0/api/applications/${id}`;

      return await agentBackOffice.get(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.errorText || err.message;
    }
  };

  updateApplication = async (payload) => {
    const { id, formData } = payload;
    const { agentBackOffice } = this;
    try {
      const url = `v1.0/api/application/${id}`;

      return await agentBackOffice.post(url, formData);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.response;
    }
  };

  duplicateApplicationsById = async (id) => {
    const { agentBackOffice } = this;

    try {
      const url = `v1.0/api/application-clone/${id}`;

      return await agentBackOffice.post(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.errorText || err.message;
    }
  };

  deleteApplication = async (id) => {
    const { agentBackOffice } = this;
    try {
      const url = `v1.0/api/application/${id}`;

      return await agentBackOffice.delete(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.response;
    }
  };

  removeAttachment = async (payload) => {
    const { id, fileName } = payload;
    const { agentBackOffice } = this;
    try {
      const url = `v1.0/api/application/${id}/attachment?filename=${fileName}`;

      return await agentBackOffice.delete(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.response;
    }
  };
}

const Service = new OnlineFormService();

export default Service;
export { Service as OnlineFormService };
